import React, { useState } from 'react';
import cn from 'classnames';

import './index.scss';

import {
  Section,
  Container,
  Row,
  Column,
  Text,
  Link,
  Image,
} from 'components/atoms';
import BannerForm from 'components/molecules/BannerForm';

import { useOffices, useOptions } from 'graphql/hooks';

const BannerContact = ({ data, className }) => {
  const { email_block, success_image, success_title, success_subtitle } = data;
  const {
    image: { source_url, alt_text, title: image_title, media_details },
  } = email_block;
  const {
    source_url: success_source_url,
    alt_text: success_image_alt,
    title: success_image_title,
    media_details: success_media_details,
  } = success_image;
  const address_list = useOffices();
  const {
    options: { contact_email },
  } = useOptions();

  const [formState, setFormState] = useState(true);
  const [animationState, setAnimationState] = useState(false);

  const contactBannerData = [
    {
      type: 'text',
      title: 'Or, you can just send an email: ',
      tag: 'h4',
      color: 'transparent-green',
      className: `${className}__section-title__title`,
    },
    {
      type: 'email',
      title: contact_email,
      tag: 'p',
      color: 'pink',
      href: `mailto:${contact_email}`,
      isRouter: false,
      className: `${className}__section-title__link__email `,
    },
  ];

  return (
    <Section className={className}>
      <Container>
        {formState ? (
          <div
            className={cn(
              `${className}_section-form`,
              animationState && `${className}_section-form_animation`
            )}
            onTransitionEnd={() => {
              if (animationState) {
                const footer = document.querySelector('footer');
                footer.classList.add('footer_before-animation');
                setFormState(false);
              }
            }}
          >
            <BannerForm
              title="Contact us – warm response guaranteed"
              onSubmit={() => {
                setAnimationState(true);
              }}
            />
            <Row className={`${className}__section-title__row`}>
              <Column
                className={`${className}__section-title offset-1 offset-lg-2`}
                lg="5"
                col="10"
              >
                {contactBannerData.map(
                  ({ type, tag, className, color, title, href }, id) => {
                    switch (type) {
                      case 'text':
                        return (
                          <Text
                            tag={tag}
                            className={className}
                            color={color}
                            key={id}
                          >
                            {title}
                          </Text>
                        );

                      case 'email':
                        return (
                          <Link
                            className={className}
                            href={href}
                            isRouter={false}
                            key={id}
                          >
                            <Text
                              tag={tag}
                              color={color}
                              key={id}
                              className="headline-3"
                            >
                              {title}
                            </Text>
                          </Link>
                        );

                      default:
                        return null;
                    }
                  }
                )}
              </Column>
              <Column lg="3">
                <Image
                  className={`${className}__image`}
                  src={source_url}
                  alt={alt_text}
                  title={image_title}
                  width={media_details?.width}
                  height={media_details?.height}
                />
              </Column>
            </Row>
          </div>
        ) : (
          <div
            className={cn(
              `${className}_section-success`,
              animationState && `${className}_section-success_animation`
            )}
            onTransitionEnd={() => {
              const footer = document.querySelector('footer');
              footer.classList.add('footer_animation');
            }}
          >
            <Row
              className={`${className}__section-title__row ${className}__section-title__row_success`}
            >
              <Column
                lg="3"
                col="6"
                className={`${className}__section-title__col offset-3 offset-lg-2`}
              >
                <Image
                  className={`${className}__image ${className}__image_success`}
                  src={success_source_url}
                  alt={success_image_alt}
                  title={success_image_title}
                  width={success_media_details?.width}
                  height={success_media_details?.height}
                />
              </Column>
            </Row>
            <Row className={`${className}__section-title__row`}>
              <Column
                className={`${className}__section-title offset-2 offset-lg-2`}
                lg="5"
                col="8"
              >
                <Text
                  className={`${className}__section-title__title ${className}__section-title__title_success`}
                  tag="h2"
                  color="transparent-green"
                >
                  {success_title}
                </Text>
                <Text className="body-lg" color="transparent-green">
                  {success_subtitle}
                </Text>
              </Column>
            </Row>
          </div>
        )}

        <Row
          className={cn(
            `${className}__container-items__row`,
            animationState &&
              `${className}__container-items__row_before-animation`,
            !formState && `${className}__container-items__row_animation`
          )}
        >
          <Column className={`${className}__container-items`}>
            {address_list?.map(
              (
                {
                  node: {
                    acf: {
                      tag = 'p',
                      color = 'transparent-green',
                      country,
                      address,
                      phone,
                    },
                  },
                },
                id
              ) => (
                <div className={`${className}__item`} key={id}>
                  <Text
                    tag={tag}
                    className={`${className}__item__category subheader`}
                    color={color}
                  >
                    {country}
                  </Text>
                  <Text
                    tag={tag}
                    className={`${className}__item__title body-sm_bold`}
                    color={color}
                  >
                    {address}
                  </Text>
                  <Link
                    href={phone ? `tel:${phone.replace(/\s/g, '')}` : ''}
                    isRouter={false}
                  >
                    <Text
                      tag={tag}
                      color={color}
                      className={`${className}__item__number body-sm_bold`}
                    >
                      {phone ? phone : ''}
                    </Text>
                  </Link>
                </div>
              )
            )}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default BannerContact;
