import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Text, Button, Input, Row, Column } from 'components/atoms';
import { getHost } from 'utils/api';

const sendForm = (company, name, phone, email, describe) => {
  let data = JSON.stringify({
    created_by: 8,
    form_id: '2',

    input_1: company,
    input_2: name,
    input_3: phone,
    input_4: email,
    input_5: describe,
  });

  let xhr = new XMLHttpRequest();
  xhr.withCredentials = true;

  xhr.addEventListener('readystatechange', function() {
    if (this.readyState === 4) {
      // console.log(this.responseText);
    }
  });

  xhr.open('POST', `${getHost()}/wp-json/gf/v2/forms/2/submissions`);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.setRequestHeader(
    'Authorization',
    'Basic Y2tfZGU0OTczOGRjMTI2Zjg5NDVjZmUwMDI3NDEyYzVkMjE1ZGI3NDUyOTpjc185ZGJhYWM2MDdhZGY0NTZjZmVmMDMzNjg1MDk4NzFjOTQ1MTYyODll'
  );

  xhr.send(data);
};

const validation = (phoneValue, emailValue, setValidationState) => {
  if (
    // phoneValue !== '' &&
    /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(emailValue)
  ) {
    setValidationState(true);
  } else {
    setValidationState(false);
  }
};

const emptyFields = (refArray, setEmptyFields) => {
  refArray.every(item => {
    if (item.current.value === '') {
      item.current.focus();
      setEmptyFields(true);
      return false;
    } else {
      setEmptyFields(false);
    }
    return true;
  });
};

const BannerForm = ({ className, title, onSubmit }) => {
  const nameRef = useRef(null);
  const companyRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const textAreaRef = useRef(null);
  const [validationState, setValidationState] = useState(false);
  const [emptyFieldsState, setEmptyFieldsState] = useState(false);

  return (
    <Row className="banner-contact__section-form">
      <Column className={`offset-lg-2`} lg="8">
        <Text
          tag="h1"
          color="transparent-green"
          className="banner-contact__section-form__title"
        >
          {title}
        </Text>
        <form
          noValidate
          className={cn('banner-contact__section-form__form')}
          onSubmit={e => {
            e.preventDefault();
            emptyFields([emailRef], setEmptyFieldsState);
            if (validationState && !emptyFieldsState) {
              sendForm(
                companyRef.current.value,
                nameRef.current.value,
                phoneRef.current.value,
                emailRef.current.value,
                textAreaRef.current.value
              );
              onSubmit();
            }
          }}
          onChange={() => {
            validation(
              phoneRef.current.value,
              emailRef.current.value,
              setValidationState
            );
            if (emptyFieldsState) {
              emptyFields([emailRef], setEmptyFieldsState);
            }
          }}
        >
          <Input
            className="banner-contact__section-form__input"
            placeholder="Company name"
            ref={companyRef}
            activePlaceholder
          />
          <Input
            className="banner-contact__section-form__input"
            placeholder="Your name"
            ref={nameRef}
            activePlaceholder
          />
          <Input
            className="banner-contact__section-form__input"
            placeholder="Phone number"
            ref={phoneRef}
            activePlaceholder
          />
          <Input
            className="banner-contact__section-form__input"
            placeholder="Email address"
            type="email"
            ref={emailRef}
            isRequired
            activePlaceholder
            altPlaceholder="Your e-mail"
          />
          <Input
            className="banner-contact__section-form__text-area"
            placeholder="Describe the project"
            ref={textAreaRef}
            isTextArea
            activePlaceholder
          />
          <Button
            variant="inline"
            title="Send"
            className={cn('banner-contact__section-form__button', {
              'banner-contact__section-form__button_disabled': !validationState,
            })}
            type="submit"
          />
        </form>
      </Column>
    </Row>
  );
};

BannerForm.defaultProps = {
  className: '',
};

BannerForm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default BannerForm;
